// @flow

import React from "react";
import {
    CreateInspectionLayout,
    InspectionSearchInspectionPoint as _SearchInspectionPoint,
    Layout,
    withPrivateRoute,
} from "@containers";

/**
 * Home Page
 */
const SearchInspectionPoint = (props: *) => (
    <Layout {...props}>
        <CreateInspectionLayout>
            <_SearchInspectionPoint />
        </CreateInspectionLayout>
    </Layout>
);

export default withPrivateRoute(SearchInspectionPoint);
